<template>
  <div>
    <b-navbar :fixed-top="true" type="is-primary">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img :src="require('@/assets/logo.png')" alt="SiLog" />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-dropdown label="Oftalmologistas">
          <b-navbar-item tag="router-link" to="/doctor">
            Cadastrar
          </b-navbar-item>
          <b-navbar-item tag="router-link" to="/doctor/table">
            Relação
          </b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-dropdown label="Clínicas">
          <b-navbar-item tag="router-link" to="/clinic">
            Cadastrar
          </b-navbar-item>
          <b-navbar-item tag="router-link" to="/clinic/table">
            Relação
          </b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-item tag="router-link" to="/register/filter">
          Filtros
        </b-navbar-item>

        <b-navbar-item tag="router-link" to="/register/covenants">
          Convênios
        </b-navbar-item>

        <b-navbar-item tag="router-link" to="/register/services">
          Serviços
        </b-navbar-item>
      </template>

      <!--<template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-primary">
              <strong>Sign up</strong>
            </a>
            <a class="button is-light"> Log in </a>
          </div>
        </b-navbar-item>
      </template>-->
    </b-navbar>

    <section class="section">
      <div class="container">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
//import user from "../services/user";
export default {
  name: "LayoutAdmin",
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
.section {
  padding: 0.5rem !important;
}
</style>